import { CommonModule, formatCurrency } from '@angular/common';
import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { AppState } from '../../core/store';
import { getInvoiceStore } from '../../core/store/selectors/invoice.selectors';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import { mdaLookupAction } from '../../core/store/actions/lookup.actions';
import { getMda } from '../../core/store/selectors/lookup.selectors';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { SvgIconComponent } from 'angular-svg-icon';
import { Router } from '@angular/router';
import { setStateAction } from '../../core/store/actions/invoice.actions';
import {
  injectMutation,
  injectQuery,
} from '@tanstack/angular-query-experimental';
import { LookupService } from '../../core/services/lookup.service';
import { sectorsList } from '../../core/utils/revenueHeadStaticData';
import { BillsService } from '../../core/services/bills.service';
import {
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../core/store/actions/toast.actions';
import { AuthService } from '../../core/services/auth.service';
import { GeneralButtonDirective } from '../directives/buttons/general-button.directive';

@Component({
  selector: 'app-invoice-modal',
  standalone: true,
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzInputModule,
    NzInputNumberModule,
    NzGridModule,
    SvgIconComponent,
    GeneralButtonDirective,
  ],
  templateUrl: './invoice-modal.component.html',
  styleUrl: './invoice-modal.component.css',
})
export class InvoiceModal implements OnInit, OnDestroy {
  store = inject(Store<AppState>);
  router = inject(Router);
  lookupService = inject(LookupService);
  authService = inject(AuthService);
  billService = inject(BillsService);

  invoiceStoreSub: Subscription;
  mdaStoreSub: Subscription;
  form: FormGroup;

  modalState = false;
  step = signal(1);
  mdaLoading = false;
  mdaList = sectorsList.filter((sector) => sector.id === 6);
  mdaData: WritableSignal<{ id: number; name: string; code: string } | null> =
    signal(null);
  routeDirection = signal<string>('');

  mdaQueryResponse = injectQuery(() => {
    return {
      queryKey: ['mda-by-category-slug', 'mda-collection'],
      queryFn: ({ queryKey }) => {
        const [, slug] = queryKey;
        return this.lookupService.getMdaByCategorySlug({
          categorySlug: slug,
        });
      },
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    };
  });
  revenueHeadsQueryResponse = injectQuery(() => {
    return {
      queryKey: ['revenue-heads-by-slug', this.mdaData()?.id],
      queryFn: ({ queryKey }) => {
        const [, mdaId] = queryKey;
        return this.lookupService.getRevenueHeadsByMda({
          mdaId,
        });
      },
      enabled: !!this.mdaData()?.id,
      staleTime: Infinity,
      gcTime: Infinity,
    };
  });

  // API call (Login)
  mutation = injectMutation((client) => ({
    mutationFn: (billData: { [key: string]: any }) =>
      this.billService.createCompleteBill(billData),
    onSuccess: (data) => {
      this.store.dispatch(
        successToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: 'Bill created successfully',
        })
      );
      if (this.routeDirection() === 'checkout-page') {
        this.router.navigate(['user', 'payments', 'checkout'], {
          queryParams: {
            billRef: (data as any)?.billReference,
          },
        });
        return;
      }

      this.router.navigate(['/user/invoice-page'], {
        queryParams: {
          ref: (data as any)?.billReference,
        },
      });
    },
    onError: (error: any) => {
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while creating bill. Try again shortly',
        })
      );
    },
  }));

  get formatNumber() {
    return formatCurrency(this.form.get('amount').value, 'en-NG', 'NGN ');
  }

  onMdaFocus() {
    this.store.dispatch(mdaLookupAction(null));
  }

  onCancel(): void {
    this.modalState = false;
  }

  onBackClick() {
    if (this.step() < 2) {
      this.step.set(1);
    } else {
      this.step.set(this.step() - 1);
    }
  }

  submitInvoice() {
    if (!this.form.valid) {
      Object.values(this.form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    } else {
      this.step.set(this.step() + 1);
    }
  }

  goToInvoicePage() {
    this.routeDirection.set('invoice-page');
    this.onSummaryClick();
  }

  goToCheckout() {
    this.routeDirection.set('checkout-page');
    this.onSummaryClick();
  }

  onSummaryClick() {
    // todo: Figure a way to make checkout page get datas from modal
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Creating a bill. Please wait...',
      })
    );
    const tpui = this.authService.userData()?.tpui || '';
    const revenueHeadData = this.form.get('service')?.value;
    const amount = this.form.get('amount')?.value;
    this.mutation.mutate({
      tpui,
      mdaCode: this.mdaData()?.code,
      billDetails: [
        {
          revenueHeadId: revenueHeadData?.id,
          amount: parseFloat((amount as string) || '0'),
        },
      ],
    });
  }

  ngOnInit(): void {
    this.invoiceStoreSub = this.store
      .pipe(select(getInvoiceStore))
      .subscribe((invoiceStore) => {
        this.modalState = invoiceStore.modalState;
      });
    this.form.get('mda').valueChanges.subscribe((data) => {
      this.mdaData.set(data);
      this.form.get('service')?.patchValue(null);
    });
  }

  ngOnDestroy(): void {
    if (this.invoiceStoreSub) this.invoiceStoreSub.unsubscribe();
    if (this.mdaStoreSub) this.mdaStoreSub.unsubscribe();
    this.store.dispatch(setStateAction({ modalState: false }));
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      mda: ['', [Validators.required]],
      service: ['', [Validators.required]],
      amount: [null, [Validators.required]],
      description: [''],
    });
  }
}
