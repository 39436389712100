<nz-modal
  [(nzVisible)]="modalState"
  [nzFooter]="null"
  nzClosable="true"
  (nzOnCancel)="onCancel()"
  [nzWidth]="650"
  [nzTitle]="step() === 2 ? title : null"
>
  <ng-template #title>
    <button
      nz-button
      nzType="link"
      nzSize="small"
      class="flex items-center gap-x-2 mt-[-5px]"
      (click)="onBackClick()"
    >
      <svg-icon
        src="assets/svgs/chevron-down.svg"
        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
      ></svg-icon>
      Back to previous page
    </button>
  </ng-template>

  <div *nzModalContent class="px-5">
    @if (step() === 1) {
    <div class="space-y-10">
      <p class="text-lg font-bold text-center">Generate Invoice</p>

      <form
        nz-form
        class="w-full"
        [formGroup]="form"
        nzLayout="vertical"
        (ngSubmit)="submitInvoice()"
      >
        <nz-form-item>
          <nz-form-label nzFor="mda">Select MDA</nz-form-label>
          <nz-form-control>
            <nz-select
              nzPlaceHolder="Select MDA"
              nzSize="large"
              formControlName="mda"
              [nzLoading]="mdaQueryResponse.isPending()"
            >
              @for (mda of mdaQueryResponse?.data() || []; track mda.id) {
              <nz-option [nzValue]="mda" [nzLabel]="mda.name"></nz-option>
              }
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="service"
            >What do you want to pay for</nz-form-label
          >
          <nz-form-control>
            <nz-select
              nzPlaceHolder="Select"
              nzSize="large"
              formControlName="service"
              [nzLoading]="revenueHeadsQueryResponse.isPending()"
              [nzShowSearch]="true"
              [nzDisabled]="!this.mdaData()?.id"
            >
              @for (option of revenueHeadsQueryResponse.data() || []; track
              option.id) {
              <nz-option
                [nzValue]="option"
                [nzLabel]="option.revenueName"
              ></nz-option>
              }
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="amount">Amount</nz-form-label>
          <nz-form-control>
            <nz-input-number
              nzSize="large"
              class="w-full"
              formControlName="amount"
            >
            </nz-input-number>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="description">Description</nz-form-label>
          <nz-form-control>
            <textarea
              rows="4"
              nz-input
              nzSize="large"
              name="description"
              formControlName="description"
              placeholder="Enter Description"
            ></textarea>
          </nz-form-control>
        </nz-form-item>

        <button
          type="submit"
          nz-button
          nzSize="large"
          nzType="primary"
          class="w-full mt-6"
        >
          Continue
        </button>
      </form>
    </div>
    } @if (step() === 2) {
    <div class="space-y-6">
      <p class="text-lg font-bold text-center">Preview</p>

      <p class="text-2xl font-bold tracking-wide text-center text-primary-500">
        {{ formatNumber }}
      </p>

      <div>
        <p class="mb-4 font-bold text-center">Summary</p>

        <div nz-row [nzGutter]="[15, 15]">
          <div nz-col [nzSpan]="12" class="space-y-2">
            <p class="font-semibold">MDA</p>
            <p class="font-bold">{{ form.get("mda")?.value?.name }}</p>
          </div>

          <div nz-col [nzSpan]="12" class="space-y-2">
            <p class="font-semibold">What do you want to pay for</p>
            <p class="font-bold">
              {{ form.get("service")?.value?.revenueName }}
            </p>
          </div>

          <div nz-col [nzSpan]="24" class="mb-8 space-y-2">
            <p class="font-semibold">Description</p>
            <p class="font-bold">
              {{ form.get("description")?.value || "Not set" }}
            </p>
          </div>
        </div>

        <div
          [ngClass]="{ 'flex items-center justify-between gap-6 mt-6': true }"
        >
          <button
            type="button"
            appGeneralButton
            [disabled]="mutation.isPending()"
            (click)="goToInvoicePage()"
            [ngClass]="{
              'capitalize flex flex-col items-center disabled:!opacity-45 flex-1 relative': true
            }"
          >
            <span>{{
              mutation.isPending() && routeDirection() === "invoice-page"
                ? "Generating invoice..."
                : "Generate invoice"
            }}</span>

            <span [ngClass]="{ 'text-xs': true }">(Bank branch payment)</span>
            <span
              [ngClass]="{
                'absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-[36px] h-[36px] inline-flex': true
              }"
              *ngIf="
                mutation.isPending() && routeDirection() === 'invoice-page'
              "
            >
              <span
                [ngClass]="{
                  'h-full w-full rounded-full  border-[3px] border-white border-r-transparent animate-spin flex-1': true
                }"
              ></span>
            </span>
          </button>
          <button
            appGeneralButton
            [disabled]="mutation.isPending()"
            (click)="goToCheckout()"
            [ngClass]="{
              'relative flex items-center justify-center flex-1 !capitalize disabled:!opacity-45': true
            }"
          >
            <span>{{
              mutation.isPending() && routeDirection() === "checkout-page"
                ? "Creating bill..."
                : "Proceed to Checkout"
            }}</span>
            <span
              [ngClass]="{
                'absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-[36px] h-[36px] inline-flex': true
              }"
              *ngIf="
                mutation.isPending() && routeDirection() === 'checkout-page'
              "
            >
              <span
                [ngClass]="{
                  'h-full w-full rounded-full  border-[3px] border-white border-r-transparent animate-spin flex-1': true
                }"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    }
  </div>
</nz-modal>
