import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvoiceReducerInitialState } from '../reducers/invoice.reducer';

const getInvoiceReducerState =
  createFeatureSelector<InvoiceReducerInitialState>('invoice');

export const getInvoiceStore = createSelector(
  getInvoiceReducerState,
  (state) => state
);
